.visCanvas {
  canvas {
    background: black;
  }
}

.infoContainer {
  .sketchName {
    font-weight: 700;
    text-align: center;
    padding: 20px;
  }
  .canvasControls {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;

    button {
      border: 1px solid white;
      border-radius: 2px;
      background: var(--secondary);
      color: var(--primary);
      cursor: pointer;
      padding: 5px;
      width: 150px;
      font-size: 14px;
    }

    button:hover {
      opacity: 0.9;
    }

    button:active {
      opacity: 0.6;
    }
  }
}

.visCanvasContainer {
  margin-bottom: 25px;
}
