.audioSelector {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.25em;

  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: column;

    label {
      text-align: center;
    }
  }

  label {
    font-size: 15px;
  }

  select {
    outline: none;
    margin-top: 10px;
    width: 19rem;

    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    select,
    &::after {
      grid-area: select;
    }

    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0.25em;
    padding: 0.5em;

    font-size: 16px;
    cursor: pointer;
    line-height: 1.1;

    background-color: var(--secondary);
    color: white;
  }

  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid black;
    border-radius: inherit;
  }

  .select + label {
    margin-top: 2rem;
  }
}

.audioInput {
  display: flex;
  flex-direction: column;

  input {
    width: 18rem;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0.25em;
    padding: 0.5em;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    line-height: 1.1;
    background-color: var(--secondary);
    color: white;
  }
}

.audioUploadContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;

  button {
    display: inline-block;
    margin: 0;
    padding: 0.4rem 1rem;
    border: 0;
    border-radius: 0.317rem;
    background-color: var(--secondary);
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.85;
  }

  button:active {
    box-shadow: inset 0 3px 4px hsla(0, 0%, 0%, 0.5);
  }

  button:focus {
    outline: thin dotted #444;
    outline-offset: -2px;
  }
}

.or {
  font-size: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
    margin: 20px 0;
  }
}
